import React, {Fragment} from 'react';
import PageName from './PageName';
import {navigateToApolloIfTokenStillValid} from "../auth/loginScripts";

function PlaygroundContainer() {

    return (<Fragment>
            <div className={'dashboard-page'}>
                <PageName pageName={'Playground'}/>
                <div className={'deprecation-message'} data-testid={"deprecation-message"}>
                    <div>{"Playground is no longer supported, please use Apollo Studio"}</div>
                    <div> {"You will be redirected shortly"}</div>
                    <br />
                    <div>{<button className={"button primary apollo-studio-button"} onClick={navigateToApolloIfTokenStillValid}>Open Apollo Studio Explorer</button>}</div>
                </div>
            </div>
        </Fragment>
    );
}

export default PlaygroundContainer;

