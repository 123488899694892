import React from 'react';

export const getAccessToken = (inputHash) => {
    const regexInput = inputHash.match(/#(?:access_token)=([\S\s]*?)&/);
    return regexInput !== null ? regexInput[1] : undefined
};

export const LoginCallback = (props) => {
    const accessToken = getAccessToken(props.location.hash) || '';
    window.localStorage.setItem('access_token', accessToken);
    window.location.assign(window.location.origin + '/');
    return <></>;
};
