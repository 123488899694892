import './scss/App.scss';
import 'foundation-sites';
import Dashboard from "./Dashboard/Dashboard";
import logo from './assets/SPINS Sharepoint logo.png';
import React, {Component, Fragment} from 'react';
import $ from '../node_modules/jquery';
import {copyAccessTokenToClipboard, navigateToApolloIfTokenStillValid} from "./auth/loginScripts";


export class App extends Component {
    componentDidMount() {
        $(document).foundation();
        copyAccessTokenToClipboard().then(() => {
            const fiveSecondTimeout = 5000;
            setTimeout(navigateToApolloIfTokenStillValid, fiveSecondTimeout)
        })
    }

    render() {
        return (
            <Fragment>
                <div className="App">
                    <div className={"nav-title-bar"}>
                        <nav className={"nav"} data-animate="hinge-in-from-top spin-out">
                            <ul className="dropdown menu columns small-12" data-dropdown-menu={true}>
                                <li><img src={logo} alt={'SPINS logo'} className={"columns small-1 spins-logo"}/></li>
                            </ul>

                        </nav>
                    </div>

                    <div className={"top-nav-menu-end"}>
                        <button className="hollow button secondary update-button logout"
                                onClick={() => window.location.assign('/logout')}>
                            Logout
                        </button>
                    </div>
                </div>
                <Dashboard/>
            </Fragment>
        );
    }
}

export default App;
