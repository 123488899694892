import {isTokenExpired} from "../Dashboard/RouteWithAuthorization";

export const navigateToApolloIfTokenStillValid = () => {
    const authToken = localStorage.getItem('access_token');
    console.log(`isTokenExpired=${isTokenExpired(authToken)}`)
    if (!authToken || isTokenExpired(authToken)) {
        window.location.replace('/logout')
        return
    }
    const graphName = process.env.REACT_APP_SDN_APOLLO_GRAPH_NAME;
    const environment = process.env.REACT_APP_ENV;
    const headers = {'Authorization': `Bearer ${authToken}`}
    const apolloStudioUrl = `https://studio.apollographql.com/graph/${graphName}/explorer?variant=${environment}&headers=${JSON.stringify(headers)}`;

    window.location.assign(apolloStudioUrl)
}

export const copyAccessTokenToClipboard = async () => {
    const token = localStorage.getItem('access_token')
    await navigator.clipboard.writeText(token);
}