export const getLoginRedirectUrl = () => {
  const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH_AUDIENCE;
  const auth0tenant = process.env.REACT_APP_AUTH_URL;
  return `${auth0tenant}/authorize?response_type=token&client_id=${clientId}&redirect_uri=${window.location.origin}/loginCallback&audience=${audience}`
};

export const getLogoutRedirectUrl = () => {
  return `${process.env.REACT_APP_AUTH_URL}/v2/logout?returnTo=${window.location.origin}`
};
