import React, {Component, Fragment} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Login} from '../auth/components/Login';
import {LoginCallback} from '../auth/components/LoginCallback';
import {Logout} from '../auth/components/Logout';
import {RouteWithAuthorization} from './RouteWithAuthorization';
import axios from 'axios';
import PlaygroundContainer from './PlaygroundContainer';

class Dashboard extends Component {

    addTokenToAxiosInterceptor = () => {
        axios.interceptors.request.use(
            (config) => {
                config.headers.Authorization = 'Bearer ' + window.localStorage.getItem('access_token');
                return config
            },
            (error) => {
                // Do something with request error
                return Promise.reject(error)
            },
        )
    };

    render() {
        this.addTokenToAxiosInterceptor();
        return (
            <Fragment>
                <Switch>
                    <Route path="/login" component={Login}/>
                    <Route path="/loginCallback" component={LoginCallback}/>
                    <Route path="/logout" component={Logout}/>
                    <RouteWithAuthorization exact path='/' component={PlaygroundContainer}/>
                </Switch>
            </Fragment>
        );
    }
}

export default Dashboard
