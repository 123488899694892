import React from 'react';
import PropTypes from 'prop-types';

const PageName = (props) => {
    return (<div className={'page-name'}>{props.pageName}</div>);
};

PageName.propTypes = {
    pageName: PropTypes.string.isRequired
};

export default PageName;