import JwtDecode from 'jwt-decode'
import React from 'react';
import {Route} from 'react-router-dom';
import {Login} from "../auth/components/Login";
import {Logout} from "../auth/components/Logout";

export const RouteWithAuthorization = (props) => {

    const accessToken = getAccessToken();

    if (accessToken === null) {
        return <Login/>;
    }

    if (isTokenExpired(accessToken)) {
        return <Logout/>;
    }

    return <Route {...props} component={props.component}/>;
};

export function getAccessToken() {
    return window.localStorage.getItem('access_token');
}

export function isTokenExpired(accessToken) {
    return JwtDecode(accessToken).exp < Math.floor(Date.now() / 1000)
}
